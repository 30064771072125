.services-intro {
    margin-bottom: 30px;
    -webkit-box-pack: justify;
    justify-content: flex-start;
    display: flex;
    flex-flow: row;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(21, 30, 41, 0.12) 0px 2px 8px 0px;
    opacity: 1;
    padding: 20px 50px 20px 20px;
    border-radius: 10px;
    line-height: 22px;
    font-size: 17px;
    color: #0f97ab;
  }


  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .services-intro {
      font-size: 14px;
      margin-top: 30px;
    }
  }  
  .intro-services {
    padding: 40px 0px 40px 0px;
    width: auto;
    border-top: 1px solid #E6E7EC;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .intro-services {
      padding: 0px 0px 40px 0px;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .home-services {
      flex-direction: column;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
    .home-services {
      padding: 0px 0px 40px 30px;
      flex-direction: column;
    }
  }
  
  .center-container{
    display: flex;
    justify-content: center;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .center-container {
      flex-direction: column;
   
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .center-container {
      flex-direction: column;
    }
  }
  .left-intro-container {
    width: 50%;
    padding: 0px 50px 0px 0px;
    
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .left-intro-container {
      flex-direction: column;
      width: auto;
      padding: 50px 35px 0px 40px;
      margin: 0;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .left-intro-container {
      padding: 20px 40px 0px 40px;
      width: auto;
    }
  }
  
  .middle-intro-container {
    width: 35%;
    margin-left: 30px;
  
  }
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .middle-intro-container {
    flex-direction: column;
    width: auto;
    width: 85%;
    padding-left: 10px;
    margin-left: 20px;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .middle-intro-container {
      width: auto;
      padding: 50px;
    }
  }
  
  .services-box-header {
    color: rgb(21, 30, 41);
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color:#03A6C4;
    line-height: 2px;
    text-underline-position: under;
    text-transform: uppercase;
    font-size: 16px;
  }
  
  .check{
    padding-right: 20px;
  }