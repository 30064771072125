.contact-container {
  padding: 40px 0px 70px 0px;
  margin-bottom: 50px;
  text-align: center;
  background: #F9F9FA;
}
@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .contact-container {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .contact-container {
   
    padding: 70px 80px 0px 60px;
  }
}
