.about-hero {
    display: flex;
    border-bottom: 1px solid #E6E7EC;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-hero {
      flex-direction: column-reverse;
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-hero {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-idth: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .home-image {
      margin-bottom: 50%;
    }
  }
  

  .right-hero-container {
    width: 50%;
    padding: 70px 80px 70px 60px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .right-hero-container {
      width: auto;
      padding: 20px 35px 0px 20px;
    }
  }
    
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .right-hero-container {
      padding: 20px 0px 0px 50px;
      width: auto;
    }
  }
  
  .left-hero-container {
    flex: 1;
    padding-top: 50px;
  }
  
  @media only screen and (min-width: 481px) and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
    .left-hero-container {
      width: 350px;
      padding: 40px 10px 10px 0px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .left-hero-container  {
      width: auto;
      padding-top: 10px;
    }
  }
  
  
  .about-image-container {
    transition: height 0.5s ease;
    background-color: #90d0fc;
    width: 400px;
    padding-bottom: 20px;
    padding-left: -60px;
    margin-left: 90px;
    padding: 25px 0 20px 0;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-image-container {
      background: transparent;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .about-image-container {
      background: transparent;
    }
  }

  .icon-image{
    height: 30px;
    margin: 18px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .icon-image {
      height: 20px;
      width:20px;
    }
  }

  .address-icon {
    height: 30px;
    margin: 18px;
    transition: transform .2s;
  }

  .address-icon:hover{
    transform: scale(1.2);
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .address-icon {
      height: 30px;
      width:30px;
    }
  }
  
  .about-image {
    z-index: 2;
    width: 500px;
    padding-left: -40px;
    margin-left: -50px;
    box-shadow: rgba(21, 30, 41, 0.21) 0px 2px 8px 0px;
    opacity: 1;
    border-radius: 10px;
    margin-top: 30px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-image {
      width: 250px;
      margin-left: 0px;
      box-shadow: none;
      width: 300px;
    }
  }
  
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .about-image {
      width: 300px;
      box-shadow: none;
      margin: 0;
    }
  }
  
  .about-banner {
    color: rgb(21, 30, 41);
    font-size: 34px;
    font-weight: 500;
    line-height: 43px;
    text-decoration: underline;
    text-decoration-color: rgb(113, 105, 224);
    line-height: 10px;
    padding-bottom: 20px;
  }
  
.wrapper{
  display: flex;
  flex-direction: row;
}
