
  .box{
    margin: 35px 0px 40px 0px;
    justify-content: flex-start;
    display: flex;
    flex-flow: row;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(21, 30, 41, 0.12) 0px 2px 8px 0px;
    opacity: 1;
    padding: 20px 0px 10px 10px;
    font-size: 17px;
    display: flex;
    align-items: center;
    width: auto;
    color: #0f97ab;
    border-radius: 10px;
    text-align: left;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .box {
    margin-right: 20px;
    font-size: 14px;
    }
  }


  .box li{
    list-style:none;
    margin: 15px 15px 30px 55px;
    border-bottom: 1px solid #E6E7EC;
    padding-bottom: 1.2em;
  }


  .about-services {
    padding: 40px 0px 40px 0px;
    width: auto;
    border-top: 1px solid #E6E7EC;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .home-services {
      flex-direction: column;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .home-services {
      padding: 0px 0px 40px 30px;
      flex-direction: column;
    }
  }
  
  .center-container{
    display: flex;
    justify-content: center;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .center-container {
      flex-direction: column;
    }
  }
  
  .left-about-container {
    width: 50%;
    padding: 0px 80px 70px 0px; 
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .left-about-container {
      padding: 20px 35px 50px 40px;
      text-align: left;
      width: auto;
      padding-top: 0px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .left-about-container {
      width: auto;
      padding: 0px 40px 0px 40px;
    }
  }
  
  .middle-about-container {
    margin-left: 35px;
    width: 35%;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) {
    .middle-about-container {
      flex-direction: column;
      width: 85%;
      padding: 20px 35px 0px 40px;
      margin-left: 0px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .middle-about-container {
      width: 80%;
      padding: 0px 40px 0px 40px;
      text-align: center;
    }
  }
  
  .right-about-container {
    padding: 0px 30px 20px 30px;
    text-align: center;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .right-about-container {
      width: auto;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .right-about-container {
      width: auto;
      padding: 0px 40px 30px 40px;
      text-align: center;
    }
  }
  
  .services-box-header {
    color: rgb(21, 30, 41);
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color:#03A6C4;
    line-height: 2px;
    text-underline-position: under;
    text-transform: uppercase;
    font-size: 16px;
  }
  
  
  .check{
    padding-right: 20px;
  }

  .training{
    text-align: center;
    background: #F9F9FA;
    padding: 40px 0px 40px 0px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .qualification {
      width: auto;
      padding: 20px 35px 0px 40px;
      text-align: left;
    }
  }

 