.services-box {
  margin-bottom: 30px;
  -webkit-box-pack: justify;
  justify-content: flex-start;
  display: flex;
  flex-flow: row;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(21, 30, 41, 0.12) 0px 2px 8px 0px;
  opacity: 1;
  padding: 20px;
  border-radius: 10px;
  margin-right: 10px;
  line-height: 22px;
  font-size: 17px;
  margin-left: 20px;
  color: #0f97ab;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .services-box {
    font-size: 14px;
  }
}


@media only screen and (min-width: 481px) and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
 .services-box {
    font-size: 16px;
  }
}

.home-services {
border-top:1px solid #E6E7EC;
  padding: 40px 0px 40px 0px;
  width: auto;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .home-services {
    flex-direction: column;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .home-services {
    padding: 0px 0px 40px 30px;
    flex-direction: column;
  }
}

.center-container{
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .center-container {
    flex-direction: column;
 
  }
}

.left-services-container {
  width: 35%;
  
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .left-services-container {
    flex-direction: column;
    width: auto;
    width: 90%;
    padding-left: 10px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .left-services-container {
    width: auto;
    padding: 0px 40px 0px 40px;
    text-align: center;
  }
}

.middle-services-container {
  width: 35%;

}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .middle-services-container {
    flex-direction: column;
    width: auto;
    width: 90%;
    padding-left: 10px;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .middle-services-container {
    width: auto;
    padding: 0px 40px 0px 40px;
    text-align: center;
  }
}

.right-services-container {
  padding: 0px 30px 0px 30px;
  text-align: center;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .right-services-container {
    width: auto;
    text-align: left;
  }
}

@media only screen and (min-width: 481px) and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 1) {
  .right-services-container {
    width: auto;
    padding: 0px 40px 30px 40px;
    text-align: left;
  
  }
}

.services-box-header {
  color: rgb(21, 30, 41);
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color:#03A6C4;
  line-height: 2px;
  text-underline-position: under;
  text-transform: uppercase;
  font-size: 16px;
}


.check{
  width:22px;
  padding-right: 20px;
}