html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Proxima", Arial, sans-serif;
  color: rgb(21, 30, 41);
}

p {
  color: rgb(21, 30, 41);
  line-height: 30px;
  font-size: 17px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1) {
  p {
    font-size: 14px;
  }
}

ul{
  padding: 0px;
}

h1{
    color: #0f97ab;
    font-size: 50px;
    font-weight: 500;
    line-height: 43px;
    text-decoration-color: #0f97ab;
    font-family:  "DINPro", Arial, sans-serif;
  
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1) {
  h1 {
    font-size: 22px;
  }
}

h2 {
    font-weight: 500;
    color: rgb(21, 30, 41);
    font-size: 27px;
    font-family: "DINPro", Arial, sans-serif;
    margin-bottom: 40px;
    line-height: 40px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1) {
  h2 {
    font-size: 20px;
  }
}

h3 {
    font-weight: 400;
    color: rgb(21, 30, 41);
    font-size: 26px;
    font-family: "DINPro", Arial, sans-serif;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1) {
  h3 {
    font-size: 18px;
  }
}

h4 {
  font-size: 27px;
  font-weight: 500;
  font-family: "DINPro", Arial, sans-serif;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1) {
  h4 {
    font-size: 16px;
  }
}

h5 {
  font-weight: 500;
}

/* BUTTON */
button {
  outline: none;
  cursor: pointer;
  display: block;
  position: relative;
  background: #ffffff;
  font-size: 16px;
  font-weight: 300;
  color: #0f97ab;
  padding: 15px 40px;
  margin: 0 auto;
  box-shadow: 0 5px #0c8a9b;
  border-radius: 10px;
  border: 1px solid #0f97ab;
}


button:hover{
  box-shadow: 0 4px #0c8a9b;
	top: 2px;
}

button:active {
	box-shadow: 0 0 #07515b;
	top: 6px;
}

@font-face {
  font-family: "Glacial";
  font-style: normal;
  src: local("Glacial"), local("Glacial"),
    url("../src/assets/fonts/GlacialIndifference-Regular.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Glacial-Bold";
  font-style: normal;
  src: local("Glacial"), local("Glacial"),
    url("../src/assets/fonts/GlacialIndifference-Bold.otf") format("opentype"); /* Safari, Android, iOS */
}


@font-face {
  font-family: "Blinker";
  font-style: normal;
  src: local("Blinker"), local("Blinker"),
    url("../src/assets/fonts/Blinker-Regular.ttf") format("truetype"); /* Safari, Android, iOS */
}


@font-face {
  font-family: "DINPro";
  font-style: normal;
  src: local("DINPro"), local("DINPro"),
    url("../src/assets/fonts/DINPro-Medium.otf") format("opentype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "Proxima";
  font-style: normal;
  src: local("Proxima"), local("Proxima"),
    url("../src/assets/fonts/Proxima\ Nova\ Reg.ttf") format("truetype"); /* Safari, Android, iOS */
}