.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 2px 0px 3px rgba(0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  max-width: 400px;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer.closed {
  transform: translateX(0);
  left:-100%;
  /* animation: slide-closed 1.0s forwards; */
  transition: transform 0.3s ease-out;
  display: none;
  background-color: transparent;
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 50px;
  align-items: center;
}

.side-drawer li {
  margin: 0.5rem 0;
}

.side-drawer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: rgb(21, 30, 41);
}

.side-drawer a:hover,
.side-drawer a:active {
  color: #90d0fc;
}

@media (min-width: 1025px) {
  .side-drawer {
    display: none;
  }
}

.close{
  width: 30px;
  height: auto;
  margin: 30px;
}
