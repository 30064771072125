.footer-main {
  position: sticky;
  width: 100%;
  height: 60px;
  color: white;
  height: auto;
  width: auto;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .footer-main {
    padding: 20px 0px 50px;
    margin-top: 0;
  }
}

.footer-container {
  display: flex;
  justify-content: space-around;
}

@media only screen and (min-width: 320px) and (max-width: 768px){
  .footer-container {
    flex-direction: column;
    text-align: center;
  }
}


.social-icon {
  margin: 5px;
}

.bcap-logo {
  border: 3px solid white;
  width: 155px;
  border-radius: 4px;
}

.psychology{
  border: 3px solid white;
  width: 155px;
  border-radius: 4px;
  margin-top: 10px;
}

.covid{
  border: 3px solid white;
  width: 90px;
  margin: 10px 0px 0px 30px;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .bcap-logo {
    width: 120px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .bcap-logo {
    width: 120px;
  }
}

.logos{
  display: flex;
  flex-direction: column;
  margin: 0px 50px 50px 50px;
}

.title{
  color:#0f97ab;
  font-family: "DINPro", Arial, sans-serif;
}

.copyright{
  margin: 25px;
  text-align: center;
}

a {
color: rgb(21, 30, 41);
}

.copyright-content{
  font-size: 15px;
}