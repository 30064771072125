.locations{
    display: flex;
}

@media only screen and (min-width: 320px) and (max-width: 781px) and (-webkit-min-device-pixel-ratio: 2) {
    .locations {
    flex-direction: column;
    }
  }

.address-container{
text-align: center;
padding: 35px;
}

.address-wrapper{
    box-shadow: 0 2px 8px 0 rgba(21,30,41,.12);
    opacity: 1;
    padding: 10px 20px 10px 0;
    margin: 40px;
    display: flex;
    border-radius: 10px;
    text-align: left;
    transition: box-shadow 0.3s ease-in-out;
}

.address-wrapper:hover {
    box-shadow: 9px 12px 18px 0 rgba(0,0,0,.1);
  }

a {
    text-decoration: none;
}



