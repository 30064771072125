.fees-container {
    padding: 60px 0px 40px 0px;
    width: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* border-top: 1px solid #E6E7EC; */
    background: #F9F9FA;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .fees-container {
      padding: 20px 0px 10px 0px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .info-container {
      flex-direction: column;
    }
  }
  
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .fees-right {
      width: auto;
      padding: 0px 20px 20px 20px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .fees-right {
      padding: 10px 40px 0px 40px;
    }
  }

  .fees-left {
    width: 50%;

  }
  
  .fees-left li {
      margin-bottom: 30px;
      justify-content: space-between;
      opacity: 1;
      padding: 15px;
      margin-right: 10px;
      line-height: 24px;
      font-size: 17px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .fees-left li {
      margin-bottom: 0px;
      font-size: 14px;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .fees-left {
      width: auto;
      padding: 0px 40px 0px 40px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .fees-left {
      padding: 10px 40px 0px 0px;
    }
  }
  
  .services-box-header {
    color: rgb(21, 30, 41);
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color:#03A6C4;
    line-height: 2px;
    text-underline-position: under;
    text-transform: uppercase;
    font-size: 15px;
  }
  
