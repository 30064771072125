.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  box-shadow: none;
  margin-left: 35px;
}


.toggle-button:focus {
  outline: none;
}

.toggle-button_line {
  width: 30px;
  height: 2px;
  background:white;
}
