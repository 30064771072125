.talk{
    display: flex;
    border-top: 1px solid #E6E7EC;
    flex-direction: column;
    text-align: center;
    padding: 30px 0 50px 0;
}

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .talk {
      flex-direction: column;
      padding-bottom: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .talk {
    display: flex;
    flex-direction: column;
    }
  }


  /* @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-hero {
      flex-direction: column;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .home-image {
      margin-bottom: 50%;
    }
  } */
  
  /* .right-talk-container {
    width: 50%;
    padding: 70px 80px 70px 60px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .right-talk-container {
      width: auto;
      padding: 20px 35px 0px 40px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .right-talk-container {
      width: auto;
      padding: 20px 0px 0px 50px;
    }
  }
  
  .left-talk-container {
    flex: 1;
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .left-talk-container {
      padding: 0px 100px 10px 0px;
      display: flex;
      justify-content: center;
    }
  } */
  
  
  /* .about-image-container {
    transition: height 0.5s ease;
    background-color: #90d0fc;
    width: 400px;
    padding-bottom: 20px;
    padding-left: -60px;
    margin-left: 90px;
    padding: 25px 0 20px 0;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-image-container {
      background: transparent;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .about-image-container {
      background: transparent;
    }
  } */

  /* .image-talk {
    padding: 55px 0 20px 0;
    margin-left: 100px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .image-talk {
      display: block;
      text-align: center;
      padding: 0px;
      margin-left: 0px;
      box-shadow: none;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .image-talk {
      width: 300px;
      box-shadow: none;
    }
  } */


.talk-image{
    width: 250px;
    border-radius: 10px;
    margin: 30px 0 30px 30px;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .talk-image {
  width:150px;
    text-align: center;
    margin-left: 0px;
    box-shadow: none;
  }
}

.talk-wrapper{
  box-shadow: 0 2px 8px 0 rgba(21,30,41,.12);
  opacity: 1;
  padding: 10px 40px;
  margin: 40px;
  display: flex;
  border-radius: 10px;
  text-align: left;
  transition: box-shadow 0.3s ease-in-out;
  width: 50%;
  align-items: center;
  margin: 0 auto;
}

@media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .talk-wrapper {
  flex-direction: column;
  }
}

