.hero-container {
    display: flex;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .hero-container {
      flex-direction: column-reverse;
      margin-bottom: 30px;
  
    }
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .hero-container {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
  }


  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .home-image {
      margin-bottom: 50%;
    }
  }
  
  .right-container {
    width: 50%;
    padding: 70px 80px 70px 60px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .right-container {
      width: auto;
      padding: 20px 35px 50px 40px;
      text-align: left;
    }
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .right-container {
      width: auto;
      padding: 70px 80px 0px 60px;
    }
  }

  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .right-about-container {
      padding: 20px 0px 0px 50px;
    }
  }
  
  .left-container {
    flex: 1;
    padding-top: 50px;
  }
  
  @media only screen and (min-width: 481px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .left-container {
      width: auto;
      padding: 0px 10px 10px 0px;
      display: flex;
      justify-content: center;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .left-container {
      width: auto;
      padding: 0px 10px 10px 0px;
      display: flex;
      justify-content: center;
    }
  }
  
  
  .about-image-container {
    transition: height 0.5s ease;
    background-color: #90d0fc;
    width: 400px;
    padding-bottom: 20px;
    padding-left: -60px;
    margin-left: 90px;
    padding: 25px 0 20px 0;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-image-container {
      width: 240px;
      padding: 30px 0 5px 0;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .about-image-container {
      width: 250px;
      padding: 25px 20px 20px 0;
    }
  }
  
  .about-image {
    z-index: 2;
    width: 500px;
    padding-left: -40px;
    margin-left: -50px;
    opacity: 1;
   
  }
  
  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-image {
      width: 300px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .about-image {
      box-shadow: 0px;
    }
  }

  .image-container{
    padding: 55px 0 20px 0;
    margin-left: 70px;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    .image-container {
      display: block;
      text-align: center;
      padding: 0px;
      margin-left: 10px;
    }
  }

