.toolbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #0f97ab;
  z-index: 1;
  box-shadow: 0 0px 3px 0 rgba(0,0,0,0.1);
}


.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}


.toolbar__logo {
 width:auto
}
.toolbar__logo a {
  text-decoration: none;
}

.toolbar_navigation-items{
  padding: 50px;
  width: 32%;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 19px;
  padding: 5px;
}

.toolbar_navigation-items li {
  padding: 0 3rem;
  position:relative;
  text-decoration:none;
  display:inline-block;
  
}

.toolbar_navigation-items a {
  text-decoration: none;
  color: white;
}

.toolbar_navigation-items li::after{
  display:block;
  content: '';
  border-bottom:1px solid #0f97ab;;
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
  transform-origin:100% 50%;
  color:white;
}
.toolbar_navigation-items li:hover:after,
.toolbar_navigation-items li:focus:after,
.toolbar_navigation-items li:active:after {
  transform: scaleX(1);
  transform-origin:0 50%;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  text-decoration: none;
     color: #ededed;
}

.logo-image{
  height: 103px;
}

 @media only screen and (max-width:768px) {
  .logo-image {
    height: 80px;
  }
}

.spacer {
  flex: 1;
}

@media (max-width: 1024px) {
  .toolbar_navigation-items {
    display: none;
  }
}

@media (min-width: 1025px) {
  .toolbar__toggle-button {
    display: none;
  }
  .toolbar__logo {
    margin-left: 0;
  }
}

.toolbar li > .active {
  color: white;
  text-decoration: underline;
}

